/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
class Service {
  constructor() {
    // Set Axios global defaults
    axios.defaults.withCredentials = true; // Include cookies with all requests
    
    // Add a request interceptor to dynamically set the base URL
    axios.interceptors.request.use(
      (config) => {
        console.log('Interceptor is running ')
        console.log('genie url in the interceptor ====>', localStorage.getItem('genieBackendApiUrl'))
        config.baseURL = localStorage.getItem('genieBackendApiUrl') ||  'https://test.com'; // Dynamically set the base URL
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  getAuthToken = () => {
    return localStorage.getItem('token') || '';
  };

  getHeader = () => {
    return {
      'Content-Type': 'application/json;charset=UTF-8',
      Accept: 'application/json',
      Authorization: `Bearer ${this.getAuthToken()}`,
    };
  };

  handleUnauthorized = () => {
    // Cookies.remove('token');
    // window.location.href = '/auth/login';
  };

  handleError = (err: { isAxiosError: boolean; response: any; message: string }) => {
    console.error('Error:', err);
    if (err.response && err.response.status === 401) {
      this.handleUnauthorized();
      return { status: 401, error: { message: 'Unauthorized' } };
    }
    if (err.response && err.response.status === 422) {
      return { status: 422, error: { message: 'Unprocessable Entity' } };
    }
    if (err.isAxiosError && err.response) {
      const errResponse = err.response;
      return {
        status: errResponse.status,
        error: errResponse.data
      };
    }
    if (err.message === 'canceled') {
      return {
        status: 451,
        error: { message: err.message }
      };
    }
    return {
      status: 400,
      error: { message: err.message || 'Something went wrong!' }
    };
  };

  get = async (url: string, params?: any, controller?: any) => {
    try {
      const response = await axios.get(url, {
        headers: this.getHeader(),
        params,
        signal: controller ? controller.signal : undefined,
        withCredentials: true
      });
      return { data: response.data, status: response.status };
    } catch (err:any) {
      throw this.handleError(err);
    }
  };

  post = async (url: string, params: {}, basePath?: string, controller?: any, addHeaders?: any) => {
    try {
      const response = await axios.post(url, params, {
        headers: { ...this.getHeader(), ...addHeaders },
        signal: controller ? controller.signal : undefined
      });
      return { data: response.data, status: response.status, success: response.status === 200 };
    } catch (err:any) {
      throw this.handleError(err);
    }
  };

  delete = async (url: string, params?: any, controller?: any) => {
    try {
      const response = await axios.delete(url, {
        headers: this.getHeader(),
        data: params,
        signal: controller ? controller.signal : undefined
      });
      return { data: response.data, status: response.status };
    } catch (err:any) {
      throw this.handleError(err);
    }
  };
}

export default new Service();
